import { DataGrid } from "devextreme-react";
import { Column, ColumnChooser, Editing, FilterRow, SearchPanel, Selection, Summary, TotalItem, } from "devextreme-react/data-grid";
import { clients } from './client';

const columns = [
  { name: 'Agente', width: '65px' },
  { name: 'Descrizione Agente', title: 'Descrizione Agente', width: '80px' },
  { name: 'Zona', title: 'Zona', width: '65px' },
  { name: 'Descrizione Zona', title: 'Descrizione Zona', width: '65px' },
  { name: 'Cliente', title: 'Cliente', width: '65px' },
  { name: 'Descrizione Cliente', title: 'Descrizione Cliente', width: '200px' },
  { name: 'Filiale', title: 'Filiale', width: '65px' },
  { name: 'Descrizione Filiale', title: 'Descrizione Filiale', width: '240px' },
  { name: 'Linea', width: '160px' },
];

// const yearColumn = [
//   { name: 'QtaP' },
//   { name: 'ValoreP' },
//   { name: 'P.M.P' },
//   { name: 'Qta1'},
//   { name: 'Valore1'},
//   { name: 'P.M.1' },
//   { name: 'Qta5' },
//   { name: 'Valore5' },
//   { name: 'P.M.5' },
// ]
// const CustomizeSum(Summary){
//   return  `First: ${formatDate(data.value, 'MMM dd, yyyy')}`;
// }
function Somma(cellSum) {
  return cellSum.value;
};
function Euro(cellEuro){
  return ('€'+ cellEuro.value);
  //`{valueFormat('€'+ cellEuro.value)}`
}
export function AgentTable() {

  return (
    <>
      <DataGrid
        id="grid"
        dataSource={clients}
        keyExpr="ID"
        columnAutoWidth={true}
        allowColumnReordering={true}
        showBorders={true}
      >
        <FilterRow visible="true" />
        <SearchPanel visible={true}
          width={240}
          placeholder="Search..." />
        <Editing
          mode="cell"
          allowUpdating={true}
          allowDeleting={false}
          allowAdding={true} />
        <ColumnChooser enabled={true} />

        <Column caption="N° AGENTE : 123 - NOME AGENTE : Salvatore La Porta" alignment="center">
          <Column caption="TARGET FW23 UOMO MULTIMARCA" alignment="center">
            {columns.map(colDef => (
              <Column allowEditing={false} dataField={colDef.name} width={colDef.width} alignment="center" title={colDef.title || colDef.name} />
            ))}
          </Column>
        </Column>

        <Column caption="VALORE TARGET: €160.000 - DELTA TARGET: €-4.000 - TOTALE QTA: 2.500 - DELTA QTA: -100" alignment="center">
          <Column caption=" VENDUTO FALL-WINTER SS22" alignment="center">
            <Column allowEditing={false} dataField="QtaP" width='90px' alignment="center" />

            <Column allowEditing={false} dataField="ValoreP" width='120px' alignment="center" format={{ style: 'currency', currency: 'EUR', minimumSignificantDigits: 3 }} />

            <Column allowEditing={false} dataField="P M P" width='90px' alignment="center" />
          </Column>

          <Column caption="VENDUTO SS23 - TOTALE" alignment="center">
            <Column allowEditing={false} dataField="Qta1" width='90px' alignment="center" />

            <Column allowEditing={false} dataField="Valore1" width='120px' alignment="center" format={{ style: 'currency', currency: 'EUR', minimumSignificantDigits: 3 }} />

            <Column allowEditing={false} dataField="P M 1" width='90px' alignment="center" />
          </Column>
          <Column caption="TARGET FW23 - EXIT 2" alignment="center">
            <Column dataField="Qta5" alignment="center" width='90px' />

            <Column dataField="Valore5" alignment="center" width='120px' format={{ style: 'currency', currency: 'EUR', minimumSignificantDigits: 3 }} />

            <Column dataField="P M 5" alignment="center" width='90px' />
          </Column>
        </Column>
        <Summary>
          <TotalItem
            column="QtaP"
            summaryType="sum"
            customizeText={Somma}
          />
          <TotalItem
            column="ValoreP"
            summaryType="sum"
            valueFormat={
              { style: 'currency', currency: 'EUR', minimumSignificantDigits: 3 }
            }
            customizeText={Euro}
          //={function ({argument,value}) {return `${argument}: ${value}`;}}
          />
          <TotalItem
            column="P M P"
            summaryType="sum"
            customizeText={Somma}
          />
          <TotalItem
            column="Qta1"
            summaryType="sum"
            customizeText={Somma}
          />
          <TotalItem
            column="Valore1"
            summaryType="sum"
            valueFormat={
              { style: 'currency', currency: 'EUR', minimumSignificantDigits: 3 }
            }
            customizeText={Euro}
          />
          <TotalItem
            column="P M 1"
            summaryType="sum"
            customizeText={Somma}
          />
          <TotalItem
            column="Qta5"
            summaryType="sum"
            customizeText={Somma}
          />
          <TotalItem
            column="Valore5"
            summaryType="sum"
            valueFormat={{ style: 'currency', currency: 'EUR', minimumSignificantDigits: 3 }}
            customizeText={Euro}
          />
          <TotalItem
            column="P M 5"
            summaryType="sum"
            customizeText={Somma}
          />
        </Summary>
        <Selection
          mode="multiple"
          showCheckBoxesMode="False"
        />
      </DataGrid>
    </>
  )
}