import 'devextreme/dist/css/dx.light.css'; // serve a importare il file CSS "dx.light.css" (che contiene gli stili predefiniti per i componenti) fornito dalla libreria DevExtreme.
import React from 'react';

import Paper from '@mui/material/Paper';

import { AgentTable } from './AgentTable';

function App() {
  return (
    <Paper>
      <AgentTable></AgentTable>
    </Paper>
  )
}

export default App;
