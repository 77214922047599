export const clients = [
    {
        "ID" : 1,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 5406,
        "Descrizione Cliente": "GIPYS S.R.L.",
        "Filiale": "001",
        "Descrizione Filiale": "GIPYS C/O PARCO COMMLE CENTRO",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 65,
        "ValoreP" : 4545,
        "P M P" : 77,
        "Qta1": 72,
        "Valore1": 4387,
        "P M 1": 61,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 2,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 5406,
        "Descrizione Cliente": "GIPYS S.R.L.",
        "Filiale": "002",
        "Descrizione Filiale": "GIPYS C/O PARCO COMMLE IL",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 77,
        "ValoreP" : 554,
        "P M P" : 44,
        "Qta1": 60,
        "Valore1": 3656,
        "P M 1": 61,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 3,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 5406,
        "Descrizione Cliente": "GIPYS S.R.L.",
        "Filiale": "003",
        "Descrizione Filiale": "GIPYS C/O PARCO COMMLE COROLLA",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 78,
        "ValoreP" : 87,
        "P M P" : 89,
        "Qta1": 72,
        "Valore1": 4387,
        "P M 1": 61,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 4,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 5406,
        "Descrizione Cliente": "GIPYS S.R.L.",
        "Filiale": "006",
        "Descrizione Filiale": "GIPYS S.R.L",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 123,
        "ValoreP" : 232,
        "P M P" : 256,
        "Qta1": 48,
        "Valore1": 2925,
        "P M 1": 61,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 5,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 5406,
        "Descrizione Cliente": "GIPYS S.R.L.",
        "Filiale": "007",
        "Descrizione Filiale": "GIPYS C/O CC DA VINCI VILLAGE",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 789,
        "ValoreP" : 78,
        "P M P" : 892,
        "Qta1": 60,
        "Valore1": 3656,
        "P M 1": 61,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 6,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 5406,
        "Descrizione Cliente": "GIPYS S.R.L.",
        "Filiale": "008",
        "Descrizione Filiale": "GIPYS S.R.L.",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 563,
        "ValoreP" : 56,
        "P M P" : 313,
        "Qta1": 48,
        "Valore1": 2925,
        "P M 1": 61,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 7,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 5406,
        "Descrizione Cliente": "GIPYS S.R.L.",
        "Filiale": "009",
        "Descrizione Filiale": "GIPYS S.R.L. CC CONCA D'ORO",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 666,
        "ValoreP" : 777,
        "P M P" : 888,
        "Qta1": 60,
        "Valore1": 3656,
        "P M 1": 61,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 8,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 5406,
        "Descrizione Cliente": "GIPYS S.R.L.",
        "Filiale": "010",
        "Descrizione Filiale": "GIPYS S.R.L. CC BELVEDERE",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 999,
        "ValoreP" : 900,
        "P M P" : 111,
        "Qta1": 60,
        "Valore1": 3656,
        "P M 1": 61,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 9,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 7570,
        "Descrizione Cliente": "PIETRO LEONE S.R.L.",
        "Filiale": "004",
        "Descrizione Filiale": "PIETRO LEONE SRL(PV CANICATTI)",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 222,
        "ValoreP" : 333,
        "P M P" : 444,
        "Qta1": 320,
        "Valore1": 19552,
        "P M 1": 61,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 10,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 9213,
        "Descrizione Cliente": "BONINI S.R.L.",
        "Filiale": "",
        "Descrizione Filiale": "",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 555,
        "ValoreP" : 693,
        "P M P" : 741,
        "Qta1": 80,
        "Valore1": 4832,
        "P M 1": 60,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 11,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 9928,
        "Descrizione Cliente": "FLAINOS S.R.L.",
        "Filiale": "002",
        "Descrizione Filiale": "FLAINOS S.R.L.",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 852,
        "ValoreP" : 963,
        "P M P" : 123,
        "Qta1": 48,
        "Valore1": 2957,
        "P M 1": 62,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 12,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 9928,
        "Descrizione Cliente": "FLAINOS S.R.L.",
        "Filiale": "005",
        "Descrizione Filiale": "FLAINOS S.R.L.",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 456,
        "ValoreP" : 789,
        "P M P" : 147,
        "Qta1": 48,
        "Valore1": 2957,
        "P M 1": 62,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 13,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 10301,
        "Descrizione Cliente": "SALAMONE NICOLO'",
        "Filiale": "",
        "Descrizione Filiale": "",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 258,
        "ValoreP" : 369,
        "P M P" : 753,
        "Qta1": 50,
        "Valore1": 3060,
        "P M 1": 61,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 14,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 10590,
        "Descrizione Cliente": "PROGETTO S.R.L.",
        "Filiale": "",
        "Descrizione Filiale": "",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 951,
        "ValoreP" : 684,
        "P M P" : 426,
        "Qta1": 60,
        "Valore1": 3656,
        "P M 1": '',
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 15,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 10795,
        "Descrizione Cliente": "H2O HOLIDAY (COSTRUZIONI E",
        "Filiale": "001",
        "Descrizione Filiale": "SAPIA SAS DI MINEO ANTONIO & C",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 625,
        "ValoreP" : 425,
        "P M P" : 968,
        "Qta1": 60,
        "Valore1": 3656,
        "P M 1": 61,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 16,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 10917,
        "Descrizione Cliente": "MICHELANGELO S.R.L.",
        "Filiale": "001",
        "Descrizione Filiale": "MICHELANGELO SRL",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 478,
        "ValoreP" : 854,
        "P M P" : 125,
        "Qta1": 40,
        "Valore1": 2341,
        "P M 1": 59,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 17,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 12265,
        "Descrizione Cliente": "FERRANTE CESARIA",
        "Filiale": "001",
        "Descrizione Filiale": "FERRANTE BAGS AND SHOES",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 362,
        "ValoreP" : 125,
        "P M P" : 685,
        "Qta1": 64,
        "Valore1": 3910,
        "P M 1": 61,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 18,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 12862,
        "Descrizione Cliente": "VITANZA FRANCESCO",
        "Filiale": "099",
        "Descrizione Filiale": "VITANZA FRANCESCO",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 120,
        "ValoreP" : 203,
        "P M P" : 562,
        "Qta1": 60,
        "Valore1": 3656,
        "P M 1": 61,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 19,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 12872,
        "Descrizione Cliente": "A&N LUXURY S.R.L.",
        "Filiale": "001",
        "Descrizione Filiale": "A&N LUXURY (PV PEDARA)",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 785,
        "ValoreP" : 451,
        "P M P" : 458,
        "Qta1": 144,
        "Valore1": 8774,
        "P M 1": 61,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 20,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 12905,
        "Descrizione Cliente": "TORRISI VINCENZO",
        "Filiale": "",
        "Descrizione Filiale": "",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 766,
        "ValoreP" : 452,
        "P M P" : 413,
        "Qta1": 40,
        "Valore1": 2480,
        "P M 1": 62,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 21,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 13467,
        "Descrizione Cliente": "GI.CA. SRLS",
        "Filiale": "001",
        "Descrizione Filiale": "KENTE'CC IBLEO",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 9874,
        "ValoreP" : 848,
        "P M P" : 564,
        "Qta1": 80,
        "Valore1": 4864,
        "P M 1": 61,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 22,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 14040,
        "Descrizione Cliente": "BONACCORSO BRAND GROUP SRLS UN",
        "Filiale": "002",
        "Descrizione Filiale": "BONACCORSO BRAND GROUP",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 654,
        "ValoreP" : 78,
        "P M P" : 99,
        "Qta1": 80,
        "Valore1": 4960,
        "P M 1": 62,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 23,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 14066,
        "Descrizione Cliente": "LEONE ACCESSORI DI GIORDANO DA",
        "Filiale": "003",
        "Descrizione Filiale": "LEONE ACCESSORI DI GIORDANO D.",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 10,
        "ValoreP" : 12,
        "P M P" : 13,
        "Qta1": 60,
        "Valore1": 3656,
        "P M 1": 61,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 24,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 14077,
        "Descrizione Cliente": "CALZATURE PARISI DI SALVATORE",
        "Filiale": "",
        "Descrizione Filiale": "",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 45,
        "ValoreP" : 68,
        "P M P" : 74,
        "Qta1": 48,
        "Valore1": 2829,
        "P M 1": 59,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 25,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 15231,
        "Descrizione Cliente": "TERESI CALZATURE S.R.L.",
        "Filiale": "",
        "Descrizione Filiale": "",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 763,
        "ValoreP" : 89,
        "P M P" : 66,
        "Qta1": 32,
        "Valore1": 1971,
        "P M 1": 62,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 26,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 15432,
        "Descrizione Cliente": "FANARA GIUSEPPE",
        "Filiale": "",
        "Descrizione Filiale": "",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 543,
        "ValoreP" : 21,
        "P M P" : 41,
        "Qta1": 56,
        "Valore1": 3434,
        "P M 1": 61,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 27,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 15786,
        "Descrizione Cliente": "ESCARPIN DI VITALE MASSIMO",
        "Filiale": "",
        "Descrizione Filiale": "",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 657,
        "ValoreP" : 1,
        "P M P" : 2,
        "Qta1": 40,
        "Valore1": 2480,
        "P M 1": 62,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 28,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 15875,
        "Descrizione Cliente": "RAGONA GIUSEPPA",
        "Filiale": "",
        "Descrizione Filiale": "",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 3,
        "ValoreP" : 4,
        "P M P" : 5,
        "Qta1": 40,
        "Valore1": 2416,
        "P M 1": 60,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 29,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 16121,
        "Descrizione Cliente": "CANTALI SALVATORE",
        "Filiale": "",
        "Descrizione Filiale": "",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 6,
        "ValoreP" : 7,
        "P M P" : 8,
        "Qta1": 32,
        "Valore1": 1971,
        "P M 1": 62,
        "Qta5": '',
        "Valore5": '',
        "P M 5": ''
    },
    {
        "ID" : 30,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 16536,
        "Descrizione Cliente": "MOOD S.R.L.S.",
        "Filiale": "",
        "Descrizione Filiale": "",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 9,
        "ValoreP" : 10,
        "P M P" : 11,
        "Qta1": 80,
        "Valore1": 4864,
        "P M 1": 61,
        "Qta5": '',
        "Valore5": '',
        "P M 5": '',
    },
    {
        "ID" : 31,
        "Agente": 40,
        "Descrizione Agente": "Grifo Vito",
        "Zona": 12,
        "Descrizione Zona": "Sicilia",
        "Cliente": 16718,
        "Descrizione Cliente": "KAORY S.R.L.",
        "Filiale": "",
        "Descrizione Filiale": "",
        "Linea": "43 LIU JO MAN SHOES",
        "QtaP": 12,
        "ValoreP" : 20,
        "P M P" : 14,
        "Qta1": 56,
        "Valore1": 3370,
        "P M 1": 60,
        "Qta5": "",
        "Valore5": '',
        "P M 5": ""
    }
]